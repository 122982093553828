import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { ISeoProps } from './models';
import ExternalScripts from './ExternalScripts';
import Fonts from './constants';

const Seo: FC<ISeoProps> = ({
  siteSettings,
  description,
  lang,
  keywords,
  title,
  externalHreflangs,
}) => {
  const siteTitle = siteSettings.siteName || '';
  const titleTemplate = title === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteLang = lang || siteSettings.lang;
  const siteDir = siteSettings?.dir?.[0] || 'ltr';
  const meta = [
    {
      name: 'robots',
      content:
        process.env.GATSBY_ACTIVE_ENV === 'production' ? 'index, follow' : 'noindex, nofollow',
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteTitle,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
  ];

  return (
    <Helmet htmlAttributes={{ lang: siteLang, dir: siteDir }} {...{ title, titleTemplate, meta }}>
      {Fonts?.map((font) => {
        return (
          <link
            key={font}
            rel="preload"
            as="font"
            href={font}
            type="font/woff"
            crossOrigin="anonymous"
          />
        );
      })}
      {externalHreflangs?.map((link) => {
        return <link rel="alternate" href={link.value} hrefLang={link.key} />;
      })}
      <ExternalScripts />
    </Helmet>
  );
};

export const query = graphql`
  fragment FragmentSeo on IPageData {
    seoMetaDescription
    seoMetaKeywords
    seoMetaTitle
    seoExternalHreflangs {
      key
      value
    }
  }
`;

export default Seo;
