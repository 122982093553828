import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { getDOMRectById, getElementOffset } from 'utils/browser';
import { isActiveSimpleNavLink } from 'utils/helpers';
import useScreenRecognition from 'hooks/useScreenRecognition';
import getParsedConnectBlockMediaLinks from 'components/ConnectBlock/parsers';

import LanguageSelector from 'components/LanguageSelector';
import ConnectBlock from 'components/ConnectBlock';

import './HeaderNavigation.scss';
import Helpers from './helpers';
import { IPropsHeaderNavigation } from './model';

interface INavStylesDatum {
  left?: number | string;
  isOneChild?: boolean;
}

const searchChildrenClassName = 'js-sub-item';
const activeLinkClassName = 'active';

const HeaderNavigation: FC<IPropsHeaderNavigation> = (props): ReactElement => {
  const {
    handleMainCategoryVisibility,
    handleSubCategoryVisibility,
    activeSubCategory,
    activeMainCategory,
    navItems,
    mediaLinks,
    hideSocialMediaBlock,
    handleOpenRedirectionModal,
    socialRedirectionLinks,
    langSelector,
    marketName,
    ariaLabel,
  } = props;

  const [navStylesData, setNavStylesData] = useState<{
    [key: string]: INavStylesDatum;
  } | null>(null);

  const { isMiddleTablet, isTablet, isMobile } = useScreenRecognition();

  const isItemOrItsChildActive = useCallback(
    (id: string | null) =>
      Helpers.isItemOrItsChildActive(id, searchChildrenClassName, activeLinkClassName),
    []
  );

  const handleStylesOnLoad = useCallback(
    (items: HeaderNavigationTypes.INavItem[]) => () => {
      const initialData = {};
      const wrapperOffset = getElementOffset('main', 'offsetLeft');

      items.forEach((item: HeaderNavigationTypes.INavItem) => {
        if (item.structure === 'Subnavigation' && item.properties.title) {
          const isOneChild = item.properties.items?.length === 1;
          initialData[item.properties.title] = {
            left: isOneChild
              ? (getDOMRectById(item.properties.title)?.left || 0) - wrapperOffset
              : 'auto',
            isOneChild,
          };
        }
      });
      setNavStylesData(initialData);
    },
    []
  );

  useEffect(() => {
    if (!navItems || !navItems.length) {
      return;
    }

    const handleOnLoad = handleStylesOnLoad(navItems);
    const debouncedHandResize = debounce(handleOnLoad, 300);

    // For handling the switching between pages
    if (window.document.readyState === 'complete') {
      handleOnLoad();
    }

    window.addEventListener('load', handleOnLoad);
    window.addEventListener('resize', debouncedHandResize);

    return () => {
      window.removeEventListener('load', handleOnLoad);
      window.addEventListener('resize', debouncedHandResize);
    };
  }, [navItems]);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement | any>) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        handleMainCategoryVisibility(null, 'onblur')();
      }
    },
    [handleMainCategoryVisibility]
  );

  const unwrappedMediaLinks = getParsedConnectBlockMediaLinks(mediaLinks);

  return (
    <div className="header-navigation-frame">
      <nav className="header-navigation" aria-label={ariaLabel}>
        {navItems.map((item: HeaderNavigationTypes.INavItem) => {
          if (item.properties.simpleLink) {
            return (
              <Link
                partiallyActive
                activeClassName={isActiveSimpleNavLink(marketName) ? activeLinkClassName : ''}
                key={item.properties.simpleLink[0].name}
                to={item.properties.simpleLink[0].url}
                className="nav-link"
              >
                <span className="nav-link-title">{item.properties.simpleLink[0].name}</span>
              </Link>
            );
          }

          if (item.properties.items && item.properties.items.length) {
            const currentId = item.properties.title;
            const currentNavStylesData: INavStylesDatum =
              (navStylesData && currentId && navStylesData[currentId]) || {};

            return (
              <div
                key={item.properties.title}
                className={classNames('nf-header-navigation-dropdown', {
                  hovered: activeMainCategory && activeMainCategory === currentId,
                  'has-active-child': isItemOrItsChildActive(item.properties.title),
                })}
                id={item.properties.title || undefined}
                onBlur={isTablet || isMobile ? undefined : handleOnBlur}
                onMouseEnter={
                  isTablet || isMobile
                    ? undefined
                    : handleMainCategoryVisibility(currentId, 'onmouseenter')
                }
                onMouseLeave={
                  isTablet || isMobile
                    ? undefined
                    : handleMainCategoryVisibility(null, 'onmouseleave')
                }
              >
                <button
                  type="button"
                  className="nav-link"
                  onClick={handleMainCategoryVisibility(currentId, 'onclick')}
                >
                  <span className="nf-header-navigation-dropdown-title">
                    {item.properties?.titleLink ? (
                      <Link
                        partiallyActive
                        activeClassName={
                          isActiveSimpleNavLink(marketName) || !item.properties.titleLink
                            ? activeLinkClassName
                            : ''
                        }
                        key={item.properties.titleLink[0].name}
                        to={item.properties.titleLink[0].url}
                      >
                        <span className="nav-link-title">{item.properties.title}</span>
                      </Link>
                    ) : (
                      <span className="nav-link-title">{item.properties.title}</span>
                    )}
                  </span>
                </button>

                <div
                  className={classNames('list-holder', {
                    'has-only-child': currentNavStylesData?.isOneChild,
                  })}
                >
                  {item.properties.items.map(
                    (subnavigation: HeaderNavigationTypes.INavSubnavigationData) => {
                      return (
                        <div
                          key={subnavigation.properties.title}
                          style={{
                            left: currentNavStylesData?.left || 'auto',
                          }}
                          className={classNames('list', {
                            'sub-category-active':
                              activeSubCategory === subnavigation.properties.title,
                          })}
                        >
                          {subnavigation?.properties?.title ? (
                            <strong
                              role="presentation"
                              className="drop-title"
                              onClick={handleSubCategoryVisibility(subnavigation.properties.title)}
                            >
                              {subnavigation.properties.title}
                            </strong>
                          ) : null}
                          <div className="drop-slide">
                            {subnavigation.properties.dropdown.map(
                              (subItem: HeaderNavigationTypes.INavItemDropdown) => (
                                <Link
                                  key={subItem.properties.simpleLink[0].name}
                                  to={subItem.properties.simpleLink[0].url}
                                  partiallyActive
                                  activeClassName={activeLinkClassName}
                                  className={`nav-link item ${searchChildrenClassName}`}
                                >
                                  {subItem.properties.simpleLink[0].name}
                                </Link>
                              )
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          }

          return null;
        })}
      </nav>
      {(isMiddleTablet || isMobile) && langSelector && (
        <LanguageSelector
          title={langSelector.langSelectorTitle}
          opener={{
            mob: langSelector.selected.langTitle,
            desktop: langSelector.selected.langTitleShort,
          }}
          languages={langSelector.languages}
        />
      )}
      {(isTablet || isMobile) && hideSocialMediaBlock === '0' && unwrappedMediaLinks?.length ? (
        <ConnectBlock
          links={unwrappedMediaLinks}
          socialRedirectionLinks={socialRedirectionLinks}
          handleOpenRedirectionModal={handleOpenRedirectionModal}
        />
      ) : null}
    </div>
  );
};

export default HeaderNavigation;
