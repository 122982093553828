import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import SimpleSection from 'components/PodcastsPageComponents/SimpleSection';
import TopFooter from './TopFooter';
import BottomFooter from './BottomFooter';
import MiddleFooter from './MiddleFooter';
import { IPropsFooter } from './model';

import './Footer.scss';

const Footer: FC<IPropsFooter> = ({ data, lang, isMobile }) => {
  const {
    links,
    mediaLinksTitle,
    mediaLinks,
    copyrightInfo: [
      {
        properties: { copyrightInfo },
      },
    ],
    contactInfo: [{ properties: contactInfo }],
    countrySelector: [
      {
        properties: { countriesColumn },
      },
    ],
    hideContactInfo,
    hideMediaLinks,
    hideCountrySelector,
    additionalTextBlock,
    countrySelectorTriggerAriaLabel,
  } = data;

  const [selectorOpen, setSelectorOpen] = useState(false);
  const [footerHeight, setFooterHeight] = useState<string>('auto');

  useEffect(() => {
    setFooterHeight(isMobile ? 'auto' : selectorOpen ? `${footerHeight}px` : 'auto');
  }, [selectorOpen, isMobile]);

  const handlerOpenRegionDropdown = (isOpenedRegionDropdown: boolean) => {
    setSelectorOpen(isOpenedRegionDropdown);
  };

  const classes = classNames('nf-footer', {
    'opened-region-dropdown': selectorOpen,
  });

  return (
    <>
      {additionalTextBlock?.length ? (
        <SimpleSection {...additionalTextBlock[0].properties} />
      ) : null}

      <footer className={classes} style={{ minHeight: footerHeight }}>
        <Container fluid>
          <TopFooter
            hideContactInfo={hideContactInfo}
            hideMediaLinks={hideMediaLinks}
            hideCountrySelector={hideCountrySelector}
            mediaLinksTitle={mediaLinksTitle}
            mediaLinks={mediaLinks}
            contactInfo={contactInfo}
            countriesColumn={countriesColumn}
            lang={lang}
            handlerOpenRegionDropdown={handlerOpenRegionDropdown}
            setFooterHeight={setFooterHeight}
            countrySelectorTriggerAriaLabel={countrySelectorTriggerAriaLabel}
          />
          <MiddleFooter links={links} />
          <BottomFooter copyrightInfo={copyrightInfo} />
        </Container>
      </footer>
    </>
  );
};

export const query = graphql`
  fragment FragmentFooter on IFooter {
    hideContactInfo
    hideMediaLinks
    hideCountrySelector
    countrySelectorTriggerAriaLabel
    links {
      properties {
        linkPath {
          name
          url
          target
        }
      }
    }
    mediaLinksTitle
    mediaLinks {
      ...FragmentConnectBlock
    }
    copyrightInfo {
      properties {
        copyrightInfo
      }
    }
    contactInfo {
      properties {
        title
        contacts {
          properties {
            contactTitle
            contactPhone
            contactText
            contactLinkAriaLabel
          }
        }
      }
    }
    countrySelector {
      properties {
        countriesColumn {
          properties {
            countriesBlocks {
              properties {
                countriesList {
                  properties {
                    countryCode
                    name
                    link
                  }
                }
                title
              }
            }
          }
        }
      }
    }
    additionalTextBlock {
      properties {
        ...FragmentSimpleSection
      }
    }
  }
`;

export default Footer;
