import React, { FC } from 'react';
import { Link } from 'gatsby';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { MiddleFooterProps } from './model';
import './MiddleFooter.scss';

const MiddleFooter: FC<MiddleFooterProps> = ({ links }) => {
  return (
    <Row>
      <Col>
        <div className="nf-footer-middle">
          <ul>
            {links &&
              links.map((linkPops) => {
                const { name, url = '/', target } = linkPops.properties.linkPath[0];

                return (
                  <li key={name} className="nf-footer-middle__link">
                    <Link to={url} className="nf-footer-middle__link-text" target={target}>
                      {name}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default MiddleFooter;
